// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ndc-js": () => import("./../../../src/pages/ndc.js" /* webpackChunkName: "component---src-pages-ndc-js" */),
  "component---src-pages-nike-js": () => import("./../../../src/pages/nike.js" /* webpackChunkName: "component---src-pages-nike-js" */),
  "component---src-pages-q-js": () => import("./../../../src/pages/q.js" /* webpackChunkName: "component---src-pages-q-js" */),
  "component---src-pages-snkrs-js": () => import("./../../../src/pages/snkrs.js" /* webpackChunkName: "component---src-pages-snkrs-js" */)
}

